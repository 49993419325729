<template functional>
  <div class="loader">
    <div class="loader__loader"></div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoader'
}
</script>
