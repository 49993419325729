import { endpoints } from './data'

const publicEndpoints = [
  endpoints.get('login'),
  endpoints.get('refreshToken'),
  endpoints.get('resetPasswordRequest'),
  endpoints.get('resetPasswordConfirm')
]

export function endpointIsPublic(url) {
  return publicEndpoints.includes(url)
}
