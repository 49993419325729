import store from '@/store'

const isLoggedIn = function() {
  if (store.getters['account/isLoggedIn']) {
    // we need to do before we enter the backend,
    // because the API service is not persisted between sessions
    store.dispatch('account/authenticateApiService')

    return true
  }

  return false
}

export { isLoggedIn }
