<template>
  <div
    :class="stateClass"
    :aria-hidden="notification === undefined"
    class="notification-bar"
    role="alert"
    data-test="notification-bar"
    @click="hideBar"
  >
    <transition name="barFade">
      <notification-bar-message
        v-if="notification"
        :notification="notification"
        @hide-message="hideBar"
      />
    </transition>
    <button class="icon-button notification-bar__close" aria-hidden="true">
      <base-icon :width="20" :height="20">
        <icon-close />
      </base-icon>
    </button>
  </div>
</template>

<script>
import NotificationBarMessage from './NotificationBarMessage.vue'
import IconClose from '@/components/base/icons/Close.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    NotificationBarMessage,
    IconClose
  },
  computed: {
    stateClass() {
      const { notification } = this

      if (notification) {
        const { level } = notification

        return {
          'notification-bar--info': level === 'info',
          'notification-bar--error': level === 'error',
          'notification-bar--warning': level === 'warning',
          'notification-bar--success': level === 'success'
        }
      }

      return ''
    },
    ...mapGetters('notification', {
      notification: 'topNotification'
    })
  },
  methods: {
    hideBar() {
      this.$store.commit(`notification/UNSET_NOTIFICATION`)
    }
  }
}
</script>

<style lang="scss">
.barFade-enter-active,
.barFade-leave-active {
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}
.barFade-enter {
  opacity: 0;
  transform: translateY(-102%);
}

.barFade-leave-to {
  transform: translateY(-102%);
}

.notification-bar {
  --notification-background: white;

  align-items: center;
  background-color: var(--notification-background);
  border-bottom: 1px solid var(--notification-text);
  color: var(--notification-text);
  display: flex;
  font-weight: bold;
  justify-content: center;
  left: 0;
  padding: 2vmin 4vmin;
  position: fixed;
  text-align: center;
  top: 0;
  transition: opacity 0.2s ease-out;
  width: 100vw;
  z-index: 100;

  @supports (display: grid) {
    display: grid;
    grid-column-gap: space(half);
    grid-template-areas: 'icon-left message icon-right';
    grid-template-columns: 1.25rem 1fr 1.25rem;
  }

  &[aria-hidden='true'] {
    opacity: 0;
    pointer-events: none;
  }

  &--info {
    --notification-background: var(--clr-highlight-background-solid);
    --notification-text: var(--clr-highlight);
  }

  &--success {
    --notification-background: var(--clr-positive-background-solid);
    --notification-text: var(--clr-positive-text);
  }

  &--error {
    --notification-background: var(--clr-error-background-solid);
    --notification-text: var(--clr-error-text);
  }
}

.notification-bar__close {
  align-items: center;
  // --notification-text: inherit;
  display: flex;
  grid-area: icon-right;
  height: 100%;
}
</style>
