export const auth = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ '@/views/auth/ResetPassword.vue'
      )
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ '@/views/auth/ForgotPassword.vue'
      )
  },
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: "logout" */ '@/views/auth/Logout.vue')
  }
]
