<template>
  <button>
    <slot name="icon-front" />
    <slot name="default" />
    <slot name="icon-back" />
  </button>
</template>

<script>
export default {
  name: 'BaseButton'
}
</script>

<style lang="scss"></style>
