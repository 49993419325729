import api from './api'
import { endpoints } from './endpoints/data'
import formatPath from './endpoints/formatPath'

export default {
  get({ page, pagesize }) {
    return api.get(`/studies/?&page_size=${pagesize}&page=${page}`)
  },

  getEntry(slug) {
    return api.get(`/studies/${slug}/`)
  },

  /**
   * `POST` a new study
   *
   * @param {Object} data
   * @returns
   */
  add(data) {
    return api.post(endpoints.get('studies'), data)
  },

  updateStatus(slug, new_status) {
    return api.post(formatPath('updateStatus', { slug }), { new_status })
  },

  deleteEntry(slug) {
    return api.delete(`/studies/${slug}/`)
  },

  postReview(slug, data) {
    return api.post(formatPath('postReview', { slug }), data)
  },
}
