export const endpoints = new Map([
  ['studies', '/studies/'],
  ['studyDetail', '/studies/{{slug}}/'],
  ['studyActivity', '/study/{{id}}/activity/'],
  ['updateStatus', '/studies/{{slug}}/status/'],

  ['postReview', '/studies/{{slug}}/add-review/'],

  ['activityDetail', '/study-activity/{{id}}/'],
  ['addComment', '/studies/{{slug}}/comment/'],
  ['commentDetail', '/comments/{{id}}/'],

  ['login', '/auth/login/'],
  ['refreshToken', '/auth/refresh-token/'],
  ['verifyToken', '/auth/verify-token/'],
  ['resetPasswordRequest', '/auth/reset-password/request/'],
  ['resetPasswordConfirm', '/auth/reset-password/confirm/'],
  ['settings', '/settings/'],

  ['userList', '/users/'],
  ['ownProfile', '/users/me-myself-and-i/'],
  ['updateProfile', '/users/me/update/'],

  ['subscriptions', '/users/subscriptions/'],
  ['subscriptionDetail', '/users/subscriptions/{{id}}/']
])
