<template>
  <div class="notification-bar-message" data-test="notification-bar-message">
    <base-icon
      :width="20"
      :height="20"
      icon-fill="stroke"
      class="notification-bar-message__icon"
    >
      <component :is="componentName" />
    </base-icon>
    {{ notification.message }}
  </div>
</template>

<script>
import IconCheck from '@/components/base/icons/Check.vue'
import IconError from '@/components/base/icons/Error.vue'

export default {
  components: {
    IconCheck,
    IconError
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null
    }
  },
  computed: {
    componentName() {
      const names = {
        error: 'IconError',
        warning: 'IconError',
        success: 'IconCheck',
        info: 'IconError'
      }

      return names[this.notification.level]
    }
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.$emit('hide-message')
    }, 5000)
  },
  beforeDestroy() {
    this.timeout = null
  }
}
</script>

<style>
.notification-bar-message {
  align-items: center;
  display: flex;
  grid-area: message;
  justify-content: center;
}

.notification-bar-message__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}
</style>
