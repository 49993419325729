export const siteNavItems = [
  { text: 'Home', icon: 'Home', routeName: 'home', exact: true },
  { text: 'Entries', icon: 'ListAlt', routeName: 'view-entries' },
  // { text: 'Events', icon: 'Event', routeName: 'view-events' },
  { text: 'Account', icon: 'Person', routeName: 'user-info' },
  {
    text: 'Logout',
    icon: 'ExitToApp',
    routeName: 'logout'
  }
]

export const siteNavItemsLoggedOut = [
  {
    text: 'Login',
    icon: 'ExitToApp',
    routeName: 'login'
  }
]
