import entriesService from '@/services/entries'

const initialArchiveState = () => ({
  pagination: {
    count: 0,
    pages: 0,
    page: 0,
    has_next: undefined,
    has_previous: undefined,
  },
  pageSize: 10,
  archive: [],
})

export const namespaced = true

export const state = () => ({
  isLoading: false,
  entries: initialArchiveState(),
  currentEntry: null,
})

export const getters = {
  bySlug: (state) => (slug) =>
    state.entries.archive.find((entry) => entry.slug === slug),
}

export const mutations = {
  ADD_ENTRIES: (state, payload) => {
    state.entries.archive = payload
  },

  ADD_ENTRY_IF_NOT_EXISTS: (state, entry) => {
    const existing = state.entries.archive.findIndex(
      (existing) => existing.uuid === entry.uuid
    )

    if (existing === -1) {
      state.entries.archive.push(entry)
    }
  },

  UPDATE_PAGINATION(state, pagination) {
    state.entries.pagination = pagination
  },

  UPDATE_PAGE_SIZE(state, size) {
    state.entries.pagination.pageSize = size
  },

  SET_LOADING: (state, isLoading) => {
    state.isLoading = isLoading || !state.isLoading
  },

  SET_CURRENT_ENTRY: (state, id) => {
    state.currentEntry = id
  },
}

export const actions = {
  async get({ state, commit }, config = {}) {
    const page = config.page || 1
    const pagesize = state.entries.pagination.pageSize
    commit('SET_LOADING', true)
    const { data } = await entriesService.get({ page, pagesize })
    commit('ADD_ENTRIES', data.results)
    commit('UPDATE_PAGINATION', data.pagination)
    commit('SET_LOADING', false)
  },
  async getEntry(slug) {
    return entriesService.getEntry(slug)
  },

  changePageSize({ commit, dispatch }, size) {
    commit('UPDATE_PAGE_SIZE', size)
    return dispatch('get')
  },
}
