<template>
  <li class="site-nav__list-item">
    <router-link
      :to="{ name: siteNavItem.routeName }"
      :exact="siteNavItem.exact"
      active-class="site-nav__link--active"
      class="site-nav__link l-stack l-stack--horizontal"
    >
      <base-icon size="20"><component :is="icon"/></base-icon>
      <span>{{ siteNavItem.text }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    siteNavItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icon: () => import(`@/components/base/icons/${this.siteNavItem.icon}.vue`)
    }
  }
}
</script>

<style lang="scss">
.site-nav__link {
  border-radius: 0.25rem;
  color: var(--clr-foreground);
  font-weight: bold;
  font-size: smaller;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  padding: 0.25rem;
  word-spacing: 0.03em;

  &--active {
    background-color: var(--clr-highlight-background);
    color: var(--clr-highlight);
  }

  &:hover {
    text-decoration: underline;
  }

  & > * + * {
    --stack-space: 0.33rem;
  }
}
</style>
