import api from '@/services/api'
import { endpoints } from './endpoints/data'

export default {
  async getToken(credentials) {
    return api.post(endpoints.get('login'), credentials)
  },

  refreshToken(refresh) {
    return api.post(endpoints.get('refreshToken'), { refresh })
  },

  verifyToken(token) {
    return api.post(endpoints.get('verifyToken'), { token })
  },

  resetPasswordRequest(email) {
    return api.post(endpoints.get('resetPasswordRequest'), { email })
  },

  resetPasswordConfirm(token, password) {
    return api.post(endpoints.get('resetPasswordConfirm'), { token, password })
  },

  getSelf() {
    return api.get(endpoints.get('ownProfile'))
  }
}
