import { endpoints } from './data'

/**
 * Replace placeholders in path data with real data
 *
 * e.g. `formatPath('studyActivity', { slug: 'test' }) => /studies/test/activity/`
 *
 * @export
 * @param {String} name - The name of the endpoint, must map to `api/endpoints/data.js`
 * @param {Object } routeParams - Real values for placeholders, e.g. slug
 *
 * @returns {String} Formatted path
 */
export default function formatPath(name, routeParams) {
  return endpoints.get(name).replace(/\{\{(.*?)\}\}/g, (match, token) => {
    return routeParams[token]
  })
}
