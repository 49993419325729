<template>
  <img
    src="@/assets/swissprepared-logo.png"
    alt=""
    class="swissprepared-logo"
  />
</template>

<script>
export default {}
</script>

<style></style>
