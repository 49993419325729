import axios from 'axios'
// import jwtDecode from 'jwt-decode'

import config from '@/data/config'
import store from '@/store/index'

import { checkTokenValidity } from '@/helper/check-token-validity'
import { endpointIsPublic } from './endpoints/public-endpoints'

const cancelSource = axios.CancelToken.source()

let api = axios.create({
  baseURL: `/api/v${config.apiVersion}`,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  },
  cancelToken: cancelSource.token
})

api.interceptors.request.use(
  async config => {
    if (endpointIsPublic(config.url)) {
      return config
    }

    const validation = checkTokenValidity()

    if (!validation.accessible && validation.refreshable) {
      const { access } = await store.dispatch('account/refreshToken')

      // overwrite auth of current request
      // vuex handles overwrite of default header for future requests
      config.headers['Authorization'] = `JWT ${access}`

      return config
    }

    if (!validation.refreshable) {
      store.commit(`notification/SET_NOTIFICATION`, {
        level: 'info',
        message: 'Your session has expired, please log in again.',
        placement: 'top'
      })

      await store.dispatch('account/logoutWithRouteSave')
    }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

export default api
