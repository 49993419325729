export const namespaced = true

export const state = () => ({
  notification: null
})

export const getters = {
  topNotification: ({ notification }) => {
    if (notification && notification.placement === 'top') {
      return notification
    } else {
      return undefined
    }
  },
  modalNotification: ({ notification }) => {
    if (notification && notification.placement === 'modal') {
      return notification
    } else {
      return undefined
    }
  }
}

export const mutations = {
  SET_NOTIFICATION(state, newNotification) {
    state.notification = newNotification
  },
  UNSET_NOTIFICATION(state) {
    state.notification = null
  }
}

export const actions = {}
