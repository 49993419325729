<template>
  <div id="app" class="app-root">
    <notification-bar />
    <header class="app-header">
      <the-logo class="app-logo" />
      <p>Scientific Board<sup>BETA</sup></p>
      <section>
        <p v-if="loggedIn">
          Logged in as <br /><b>{{ name }}</b>
        </p>
        <site-nav />
      </section>
    </header>
    <router-view class="app-content width-wrapper" />
  </div>
</template>

<script>
import TheLogo from '@/components/base/TheLogo.vue'
import SiteNav from '@/components/ui/nav/SiteNav.vue'
import NotificationBar from '@/components/ui/notification/NotificationBar.vue'

import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    TheLogo,
    SiteNav,
    NotificationBar
  },

  computed: {
    ...mapGetters({
      loggedIn: 'account/isLoggedIn'
    }),
    ...mapState({
      name: ({ account }) => account.user.name
    })
  },

  beforeMount() {
    if (this.loggedIn) {
      this.$store.dispatch('account/checkToken')
    }
  },

  head: {
    // if no subcomponents specify a head.title, this title will be used
    title: 'Loading',
    // all titles will be injected into this template
    titleTemplate: '%s | swissPrEPared Scientific Board'
  }
}
</script>

<style lang="scss">
.app-root {
  min-height: 100vh;

  @media (min-width: 707px) {
    display: flex;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 11rem 1fr;
      grid-column-gap: 2vmin;
    }
  }

  & > * {
    padding: space(full);
  }
}

.app-logo {
  max-width: 80px;
}

.app-header {
  border-right: 1px solid var(--clr-grey-50);
}

.app-content {
  margin-top: 2vmin;
  padding-bottom: 4vmin;
}
</style>
