import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import { isLoggedIn } from './guards/loggedIn'
import store from '@/store/'
import { auth } from './routes/auth'
import { entries } from './routes/entries'
import { user } from './routes/user'
import { routeIsPublic } from './config/public-routes'

Vue.use(VueRouter)
Vue.use(Meta, {
  keyName: 'head'
})

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  ...entries,
  ...user,
  ...auth,
  {
    path: '*',
    name: 'not-found',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    meta: {
      code: 404
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const loggedIn = isLoggedIn()

  if (!loggedIn && !routeIsPublic(to.name) && to.name !== 'home') {
    store.commit('account/SET_AFTER_AUTH', to)
  }

  if (routeIsPublic(to.name) || !to.matched.length) {
    return next()
  }

  if (!loggedIn) {
    const afterAuth = store.getters['account/afterAuthRoute']

    return next({
      name: 'login',
      query: { ...(afterAuth && { to: encodeURIComponent(afterAuth.path) }) }
    })
  }

  next()
})

export default router
