<template>
  <svg
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-hidden="true"
    focusable="false"
    class="base-icon"
  >
    <g
      :fill="iconFill === 'fill' ? textColor : 'transparent'"
      :stroke="iconFill === 'stroke' ? textColor : 'transparent'"
    >
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    size: {
      type: [Number, String],
      default: 24
    },
    textColor: {
      type: String,
      default: 'currentColor'
    },
    iconFill: {
      type: String,
      default: 'fill',
      validator: val => val === 'fill' || val === 'stroke'
    }
  }
}
</script>

<style>
.base-icon {
  flex: 0 0 auto;
  display: inline-block;
  vertical-align: baseline;
}
</style>
