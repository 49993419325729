export const entries = [
  {
    path: '/entries',
    component: () =>
      import(/* webpackChunkName: "entries-root" */ '@/views/entries/Root.vue'),
    children: [
      {
        path: '',
        name: 'view-entries',
        component: () =>
          import(
            /* webpackChunkName: "entries-view" */ '@/views/entries/List.vue'
          )
      },
      {
        path: 'add',
        name: 'add-entry',
        component: () =>
          import(
            /* webpackChunkName: "entries-add" */ '@/views/entries/Add.vue'
          )
      },
      {
        path: ':slug',
        name: 'entry',
        component: () =>
          import(
            /* webpackChunkName: "entry-detail" */ '@/views/entries/Detail.vue'
          )
      }
    ]
  }
]
