import Vue from 'vue'
import Vuex from 'vuex'

import plugins from './plugins'

import * as account from './modules/account'
import * as entries from './modules/entries'
import * as notification from './modules/notification'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    entries,
    notification
  },
  plugins
})
