import api from '@/services/api'
import auth from '@/services/auth'
import site from '@/services/site'

import router from '@/router'

import { checkTokenValidity } from '@/helper/check-token-validity'
import userService from '@/services/user'

export const namespaced = true

export const state = () => ({
  user: {},
  token: {},
  subscriptions: [],
  settings: null,
  afterAuth: null
})

export const getters = {
  isLoggedIn: state => !!state.token?.access,
  afterAuthRoute: state => state.afterAuth,
  subscriptionById: state => studyId =>
    state.subscriptions.find(sub => {
      if (sub.study.id) {
        return sub.study.id === studyId
      }

      return sub.study === studyId
    })
}

export const mutations = {
  SET_USER_TOKEN(state, tokenData) {
    state.token = { ...state.token, ...tokenData }
  },

  UNSET_USER_TOKEN(state) {
    state.token = {}
  },

  SET_USER_INFO(state, infos) {
    state.user = infos
  },

  SET_AFTER_AUTH(state, route) {
    state.afterAuth = route
  },

  SET_SETTINGS(state, settings) {
    state.settings = settings
  },

  ADD_SUBSCRIPTIONS(state, data) {
    if (Array.isArray(data)) {
      state.subscriptions = data

      return
    }

    state.subscriptions.push(data)
  },

  REMOVE_SUBSCRIPTION(state, id) {
    const index = state.subscriptions.findIndex(
      subscription => subscription.id === id
    )

    state.subscriptions.splice(index, 1)
  }
}

export const actions = {
  async login({ commit, dispatch }, credentials) {
    const response = await auth.getToken(credentials)

    if (response.status === 200) {
      commit('SET_USER_TOKEN', response.data)
      dispatch('authenticateApiService')

      await dispatch('getSettings')
      const { data: userData, status } = await auth.getSelf()

      if (status === 200) {
        commit('SET_USER_INFO', userData)
      }
    }

    return response
  },

  async getSubscriptions({ commit }) {
    const { data } = await userService.getSubscriptions()

    commit('ADD_SUBSCRIPTIONS', data)
  },

  async removeSubscription({ commit }, id) {
    await userService.removeSubscription(id)

    commit('REMOVE_SUBSCRIPTION', id)
  },

  logout({ commit }) {
    commit('UNSET_USER_TOKEN')
    commit('SET_USER_INFO', {})

    delete api.defaults.headers['Authorization']

    router.push({ name: 'login' })
  },

  logoutWithRouteSave({ commit, dispatch, rootState }) {
    if (rootState.route.name !== 'home') {
      commit('SET_AFTER_AUTH', { path: rootState.route.path })
    }
    dispatch('logout')
  },

  async refreshToken({ commit, dispatch, state }) {
    try {
      const { data } = await auth.refreshToken(state.token.refresh)

      commit('SET_USER_TOKEN', data)
      dispatch('authenticateApiService')

      return data
    } catch (e) {
      dispatch('logoutWithRouteSave')
    }
  },

  async checkToken({ dispatch, commit }) {
    const validation = checkTokenValidity()

    if (!validation.accessible && validation.refreshable) {
      await dispatch('refreshToken')
    }

    if (!validation.refreshable) {
      commit(`notification/SET_NOTIFICATION`, {
        level: 'info',
        message: 'Your session has expired, please log in again.',
        placement: 'top'
      })

      await dispatch('account/logoutWithRouteSave')
    }
  },

  async getSettings({ commit }) {
    try {
      const { data } = await site.getSettings()

      commit('SET_SETTINGS', data)
    } catch (err) {
      console.error(err)
    }
  },

  authenticateApiService({ state }) {
    api.defaults.headers['Authorization'] = `JWT ${state.token.access}`
  }
}
