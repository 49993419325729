import { endpoints } from './endpoints/data'
import api from './api'

import formatPath from './endpoints/formatPath'

export default {
  searchUserNames(string) {
    return api.get(`${endpoints.get('userList')}?search=${string}`)
  },

  update(data) {
    return api.patch(endpoints.get('updateProfile'), data)
  },

  getSubscriptions(queryParam = '') {
    return api.get(`${endpoints.get('subscriptions')}?${queryParam}`)
  },

  addSubscription(data) {
    return api.post(endpoints.get('subscriptions'), data)
  },

  removeSubscription(id) {
    return api.delete(formatPath('subscriptionDetail', { id }))
  }
}
