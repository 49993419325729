import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import Vuelidate from 'vuelidate'

import App from './App.vue'
import router from './router'
import store from './store'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseLoader from '@/components/base/BaseLoader.vue'
import TheStack from '@/components/base/layout/TheStack.vue'

import '@/styles/app.scss'

const baseComponents = [BaseButton, BaseIcon, BaseLoader, TheStack]

Vue.config.productionTip = false

Vue.use(Vuelidate)

sync(store, router)

baseComponents.forEach(component => {
  Vue.component(component.name, component)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
