<template>
  <nav class="site-nav" aria-label="Main">
    <the-stack tag-name="ul" class="site-nav__list">
      <site-nav-item
        v-for="siteNavItem in shownNavItems"
        :key="siteNavItem.text"
        :site-nav-item="siteNavItem"
      />
    </the-stack>
  </nav>
</template>

<script>
import SiteNavItem from './SiteNavItem.vue'

import { mapGetters } from 'vuex'

import { siteNavItems, siteNavItemsLoggedOut } from '@/data/navigation'

export default {
  components: {
    SiteNavItem
  },

  computed: {
    ...mapGetters({
      loggedIn: 'account/isLoggedIn'
    }),
    shownNavItems() {
      return this.loggedIn ? siteNavItems : siteNavItemsLoggedOut
    }
  },
}
</script>

<style lang="scss">
.site-nav__list {
  list-style: none;

  & > * + * {
    --stack-space: 0.5rem;
  }
}
</style>
